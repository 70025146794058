import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_result_item = _resolveComponent("result-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_result_item, {
      id: "5",
      title: '5 - Açık İnovasyon',
      "title-class": 'page-heading font-semibold',
      description: `Açık inovasyon yaklaşımı, bilginin bu kadar yaygın hale geldiği ve, kurumların
         kendi iç kaynakları ile kendi araştırmalarını yapmalarının artık çok sınırlayıcı olduğu bir dünyada,
         dış kaynaklardan gelecek fikir, patent ve projelerin değerlendirilmesi ve
         üniversiteler, teknoloji transfer ofisleri, araştırma merkezleri ve startup'lar gibi kurum dışı
         paydaşlar ile işbirliklerinin geliştirilmesini savunan bir metodolojidir. Kurumlar, faaliyet
         gösterdikleri sektör ile ilgili açık inovasyon yarışmaları, demoday ve hackathon gibi
         etkinlikler düzenleyerek, ekosistemdeki paydaşları ile yakın işbirlikleri kurabilirler.
         <p class='mt-4'>Vermiş olduğunuz yanıtlar neticesinde, İnovasyon Yetkinlik Envanterinizin Açık İnovasyon boyutundaki skoru <b>${ _ctx.detailReport.openInnovation_Score }</b> olarak hesaplanmıştır.<p>`,
      "benchmark-result": _ctx.detailReport.openInnovation_Benchmark.toString(),
      "score-result": _ctx.detailReport.openInnovation_Score.toString(),
      "table-title": 'Tablo 5'
    }, null, 8, ["description", "benchmark-result", "score-result"]),
    _createVNode(_component_result_item, {
      id: "51",
      title: '5.1. İşbirliği Altyapısı',
      description: `İşbirliği altyapısı başlığı altında size yönelttiğimiz sorularda, kurumunuzun açık inovasyon
         kapsamında yaptığı işbirlikleri ile ilgili olarak nasıl bir politika izlediğini ve hangi araçları
         aktif olarak kullandığını sorguladık. Yaptığımız analiz kapsamında, kurumunuzun işbirliği
         kurma prensiplerini, işbirliğinin verimliliğini ölçmeye yönelik olarak kullandığı göstergelerini ve dış
         ekosistem ile hangi yöntemleri kullanarak irtibata geçtiğini değerlendirdik. Bu
         çerçevede, kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu
         aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.openInnovation_outsource_Benchmark.toString(),
      "score-result": _ctx.detailReport.openInnovation_outsource_Score.toString(),
      "table-title": 'Tablo 5.1',
      "detection-list": _ctx.detailReport.openInnovation_outsource_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "52",
      title: '5.2. Startup İşbirlikleri',
      description: `Startup işbirlikleri başlığı altında size yönelttiğimiz sorularda, kurumunuzun açık inovasyon
         çalışmaları kapsamında startup'lar ile ne tür işbirlikleri yaptığını sorguladık. Yaptığımız
         analiz kapsamında, kurumunuzun startup’lar ile ürün hizmet alımı/satımı yapması, onlar ile ortak
         Ar-Ge yapması, onların sermayesine ortak olması ve onlara kendi tesislerinde PoC (Proof of Concept)
         yapma imkanı tanıması ile ilgili uygulamalarınızı değerlendirdik. Bu
         çerçevede, kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu
         aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.openInnovation_startupcooperation_Benchmark.toString(),
      "score-result": _ctx.detailReport.openInnovation_startupcooperation_Score.toString(),
      "table-title": 'Tablo 5.2',
      "detection-list": _ctx.detailReport.openInnovation_startupcooperation_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "53",
      title: '5.3. Kurum Dışı İşbirlikleri',
      description: `Bu başlık kapsamında size yönelttiğimiz sorularda, kurumunuzun inovasyon çalışmaları
         kapsamında dış kuruluşlar ile işbirliği faaliyetlerini hangi ölçüde teşvik ettiğini sorguladık.
         Yaptığımız analiz kapsamında, kurumunuzun üniversitelerin, araştırma merkezlerinin,
         teknoparkların, kuluçka merkezlerinin yanı sıra rakip firmalar, tedarikçiler ve müşterileri ile
         yürüttüğü inovasyon işbirliklerini değerlendirdik. Bu çerçevede, kurumunuzun aynı soruları
         cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.openInnovation_cooperation_Benchmark.toString(),
      "score-result": _ctx.detailReport.openInnovation_cooperation_Score.toString(),
      "table-title": 'Tablo 5.3',
      "detection-list": _ctx.detailReport.openInnovation_cooperation_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"])
  ]))
}