
import BenchmarkTable from './BenchmarkTable.vue';
export default {
  components: { BenchmarkTable },
  props: {
    title: {
      type: String,
      required: true
    },
    titleClass: {
      type: String,
      default: 'sub-page-heading'
    },
    description: {
      type: String,
      required: true
    },
    tableTitle: {
      type: String,
      required: true
    },
    benchmarkResult: {
      type: String,
      required: true
    },
    scoreResult: {
      type: String,
      required: true
    },
    detectionList: {
      type: Array,
      required: false,
      default: () => []
    }
  }
};
