import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_result_item = _resolveComponent("result-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_result_item, {
      id: "4",
      title: '4 - İnovasyon Altyapısı',
      "title-class": 'page-heading font-semibold',
      description: `Bir kurumda gerçekleştirilen inovasyon faaliyetlerinin artan ivme ile büyümesini ve
         sürdürülebilirliğini sağlamak için, finansal, teknolojik ve hukuksal bir takım altyapılara
         ihtiyaç vardır. İnovasyon stratejisinde yer alan inovasyon hedeflerini gerçekleştirebilmek
         için yeterli bütçe ayrılmalıdır. Kurumlarda inovasyon faaliyetlerinin yönetimi ile ilgili, hem
         fiziksel (inovasyon toplantı odası/kuluçka merkezi/inovasyon merkezi vb.) alanlara hem
         de IT alt yapısına (fikir toplama, değerlendirme, projelendirme süreçleri için yazılım
         entegrasyonu) ihtiyaç duyulabilmektedir. İnovasyon ile ilgili yapılacak tüm kurum içi ve
         kurum dışı faaliyetlerin ve işbirliklerinin ilgili kanun maddeleri temel alınarak hukuksal alt
         yapısının hazırlanması ve hazırlanan altyapının yönerge ve sözleşmelerle desteklenmesi
         büyük önem taşımaktadır.
         <p class='mt-4'>Vermiş olduğunuz yanıtlar neticesinde, İnovasyon Yetkinlik Envanterinizin İnovasyon Altyapısı boyutundaki skoru <b>${ _ctx.detailReport.innovationInfrastructure_Score }</b> olarak hesaplanmıştır.<p>`,
      "benchmark-result": _ctx.detailReport.innovationInfrastructure_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationInfrastructure_Score.toString(),
      "table-title": 'Tablo 4'
    }, null, 8, ["description", "benchmark-result", "score-result"]),
    _createVNode(_component_result_item, {
      id: "41",
      title: '4.1. Finansal Altyapı',
      description: `Bu başlık kapsamında size yönelttiğimiz sorularda, kurumunuzun inovasyon çalışmalarına
         özel belirlenmiş bir bütçesinin varlığını ve bu bütçenin hedeflere ulaşmak için yeterli olup
         olmadığını sorguladık. Yaptığımız analiz kapsamında, kurumunuzun inovasyon bütçesini
         oluştururken öz kaynaklara ek olarak ulusal ve uluslararası hibe ve teşviklerden
         yararlanma durumunu değerlendirdik. Bu çerçevede, kurumunuzun aynı soruları
         cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationInfrastructure_finance_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationInfrastructure_finance_Score.toString(),
      "table-title": 'Tablo 4.1',
      "detection-list": _ctx.detailReport.innovationInfrastructure_finance_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "42",
      title: '4.2. Fiziki ve Teknolojik Altyapı',
      description: `Fiziki ve teknolojik altyapı başlığı altında size yönelttiğimiz sorularda, kurumunuzun fikir
         toplama, değerlendirme ve kabul edilen fikirleri hayata geçirme noktasındaki altyapısını
         sorguladık. Yaptığımız analiz kapsamında, kurumunuzun bu tür çalışmaları yürütmeye
         yönelik özel bir alanı/mekanı ve bu konuda kullandığı BT/web tabanlı
         araçları olup olmadığını değerlendirdik. Bu çerçevede, kurumunuzun aynı soruları cevaplayan diğer
         kurumlar ile karşılaştırmalı durumu aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationInfrastructure_tech_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationInfrastructure_tech_Score.toString(),
      "table-title": 'Tablo 4.2',
      "detection-list": _ctx.detailReport.innovationInfrastructure_tech_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "43",
      title: '4.3. Hukuki Altyapı',
      description: `Hukuki altyapı başlığı altında size yönelttiğimiz sorularda, kurumunuzun kurum içerisinde
         ve dışında yürüttüğü inovasyon çalışmaları ile ilgili süreçlerin ve kuralların yer aldığı bir
         yönergesi olup olmadığını sorguladık. Yaptığımız analiz kapsamında, kurumunuzun bu
         tür çalışmaları yürütürken, İş Kanunu, Ticaret Kanunu, Sınai Mülkiyet Kanunu ve Kişisel
         Verilerin Korunması ile ilgili mevzuatın gereklerini dikkate alıp almadığını değerlendirdik.
         Bu çerçevede, kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı
         durumu aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationInfrastructure_legal_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationInfrastructure_legal_Score.toString(),
      "table-title": 'Tablo 4.3',
      "detection-list": _ctx.detailReport.innovationInfrastructure_legal_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"])
  ]))
}