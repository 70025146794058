import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_result_item = _resolveComponent("result-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_result_item, {
      id: "3",
      title: '3 - İnovasyon Süreci',
      "title-class": 'page-heading font-semibold',
      description: `
         İnovasyon süreci, yeni ve/veya mevcut bilgilerin pazarlanabilir çözümlere dönüştürülmesinin
         yolunu tanımlar. İnovasyon sürecini başlatan unsur yenilikçi fikirlerdir. Dolayısı ile,
         rekabetçiliği yüksek yenilikçi fikirlerin ortaya çıkmasını sağlamak ilk gerekliliktir. Kurumlar,
         hem kurum içi hem de kurum dışı fikirleri toplama, değerlendirme ve fikir sahibini
         ödüllendirme süreçlerini sistematik bir şekilde yönetmelidir. Fikir yönetim sistemlerinin en
         önemli özelliği, çalışanların her konudaki fikirlerini toplamak için değil, şirketin inovasyon
         stratejisinde tanımladığı ve belirli bir amaç için kullanacağı fikirleri toplamak için
         tasarlanmış olmalarıdır. Fikir toplama, değerlendirme ve karar verme süreçlerinden sonra
         fikirlerin projelendirilmesi, inovasyon sürecinin 2 aşamasıdır. Farklı birimlerden oluşturulan
         proje takımları, sistematik proje yönetimi sürecini uygulayarak fikrin ürün/hizmetlere
         dönüşmesini sağlamalı ve aynı zamanda geliştirilmekte olan inovasyon projelerinin
         mevcut regülasyonlar ile uyumu ve projelerin ticarileşme potansiyeli (Pazardaki Karşılığı)
         araştırmalıdır.
         <p class='mt-4'>Vermiş olduğunuz yanıtlar neticesinde, İnovasyon Yetkinlik Envanterinizin İnovasyon Süreci boyutundaki skoru <b>${ _ctx.detailReport.innovationManagment_Score }</b> olarak hesaplanmıştır.<p>`,
      "benchmark-result": _ctx.detailReport.innovationProcess_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationProcess_Score.toString(),
      "table-title": 'Tablo 3'
    }, null, 8, ["description", "benchmark-result", "score-result"]),
    _createVNode(_component_result_item, {
      id: "31",
      title: '3.1. Fikir Toplama ve Değerlendirme',
      description: `Bu başlık kapsamında size yönelttiğimiz sorularda, kurumunuzun çalışanlarından yeni fikirler
         toplama konusundaki motivasyonunu, toplanan fikirleri değerlendirme ve alandaki
         başarılı örnekleri ödüllendirme yöntemini sorguladık. Yaptığımız analiz kapsamında,
         kurumunuzda toplanan fikirlere yapılan geri dönüşleri ve bu fikirlerin hangi tip
         çalışanlardan toplandığını değerlendirdik. Bu çerçevede, kurumunuzun aynı soruları
         cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationProcess_Collecting_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationProcess_Collecting_Score.toString(),
      "table-title": 'Tablo 3.1',
      "detection-list": _ctx.detailReport.innovationProcess_Collecting_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "32",
      title: '3.2. Fikir Projelendirme',
      description: `Fikir projelendirme başlığı altında size yönelttiğimiz sorularda, kurumunuzun içeriden veya
         dışarıdan kendisine ulaştırılan fikirleri nasıl projelendirdiğini ve bu konuda özel inovasyon
         proje takımları kullanıp kullanılmadığını sorguladık. Yaptığımız analiz kapsamında, bu çalışmaları
         yönetmeye yönelik oluşturulmuş özel bir sürecin varlığını ve projelerin sistematik olarak tabi
         oldukları kontrol mekanizmalarını değerlendirdik. Bu çerçevede, kurumunuzun aynı
         soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationProcess_Culture_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationProcess_Culture_Score.toString(),
      "table-title": 'Tablo 3.2',
      "detection-list": _ctx.detailReport.innovationProcess_Culture_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "33",
      title: '3.3. Ticarileştirme ve Müşteri Deneyimi',
      description: `Ticarileştirme ve müşteri deneyimi başlığı altında size yönelttiğimiz sorularda,
         kurumunuzun inovasyon projeleri kapsamında müşteri mülakatları yapmaya ne oranda
         önem verdiğini sorguladık. Yaptığımız analiz kapsamında, müşteri geri bildirimlerinin ve
         pazar araştırmalarının inovasyon sürecine aktarılmasını değerlendirdik. Bu çerçevede,
         kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda
         gösterilmektedir:
         Size yönetmiş olduğumuz sorulara verdiğiniz cevaplardan yola çıkarak tespitlerimiz
         aşağıdaki şekildedir:`,
      "benchmark-result": _ctx.detailReport.innovationProcess_Capabilities_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationProcess_Capabilities_Score.toString(),
      "table-title": 'Tablo 3.3',
      "detection-list": _ctx.detailReport.innovationProcess_Capabilities_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"])
  ]))
}