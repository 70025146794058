import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_result_item = _resolveComponent("result-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_result_item, {
      id: "1",
      title: '1 - İnovasyon Stratejisi',
      "title-class": 'page-heading font-semibold',
      description: `
      Kurum stratejisi, tanımlı bir vizyon çerçevesinde oluşturulmuş hedeflerin elde edilebilmesi için hazırlanan geniş kapsamlı bir plandır.
      İnovasyon stratejisi ise, kurumun vizyonu doğrultusunda oluşturulmuş hedeflerine ulaşmasını sağlayan ve kurumun problemlerine ve ihtiyaçlarına odaklanan,
      ürün/hizmet/iş modeli bazında tanımlanmış inovasyon alanlarının planlanması olarak görülebilir.
      Kurumun inovasyon stratejisinin olmaması, yapılan inovasyon çalışmalarının belirli bir noktaya odaklanmasını ve kurumun gerçekten ihtiyacı olan projelerin ortaya çıkmasını zorlaştırır.
      Tüm paydaşların (müşteriler, rakipler, tedarikçiler, startup’lar) analiz edilerek yazılı bir doküman halinde oluşturulması gereken inovasyon stratejisinin,
      kurumiçi hedeflere indirgenmesi ve bu hedeflerin tüm organizasyon seviyelerine (üst/orta yönetime - beyaz yakalılara) iletilecek şekilde tasarlanması büyük önem arz etmektedir.
      <p class='mt-4'>Vermiş olduğunuz yanıtlar neticesinde, İnovasyon Yetkinlik Envanterinizin İnovasyon Stratejisi boyutundaki skoru <b>${ $props.detailReport.innovationStrategy_Score.toString() }</b> olarak hesaplanmıştır.<p>`,
      "benchmark-result": $props.detailReport.innovationStrategy_Benchmark.toString(),
      "score-result": $props.detailReport.innovationStrategy_Score.toString(),
      "table-title": 'Tablo 1'
    }, null, 8, ["description", "benchmark-result", "score-result"]),
    _createVNode(_component_result_item, {
      id: "11",
      title: '1.1. Stratejik Olgunluk Seviyesi',
      description: `Bu başlık kapsamında size yönelttiğimiz sorularda, kurumunuzda özel bir inovasyon stratejisinin varlığını, bu stratejinin genel kurum stratejiniz
      ile uyumunu ve bu stratejinin bir takım hedeflere dönüştürülmüş olup olmadığını sorguladık. Yaptığımız analiz kapsamında, bu alanda belirlemiş olduğunuz
      hedeflerin verimliliğini ölçmeye yönelik göstergeler oluşturmuş olmanız, bu stratejinin ürün/hizmet bazında bir ayrım içermesi ve yeni girmeyi hedeflediğiniz
      pazarları dikkate alması sorgulandı. Bu çerçevede, kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda gösterilmektedir:`,
      "benchmark-result": $props.detailReport.innovationStrategy_Maturity_Benchmark.toString(),
      "score-result": $props.detailReport.innovationStrategy_Maturity_Score.toString(),
      "table-title": 'Tablo 1.1',
      "detection-list": $props.detailReport.innovationStrategy_Maturity_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "12",
      title: '1.2. Strateji Oluşturma Yöntemi',
      description: `Bu başlık kapsamında size yönelttiğimiz sorularda, kurumunuzda inovasyon alanında bir
      strateji belirlerken hangi hususları dikkate aldığınız sorguladık. Bu kapsamda, stratejinizi
      belirlerken yaptığınız analizlerde müşterilerinizi, rakiplerinizi, tedarikçilerinizi ve sektörünüzde faaliyet
      gösteren startup'ları hangi ölçüde hesaba kattığınızı değerlendirdik. Diğer taraftan,
      piyasa aktörleri dışında ekonomik ortamı etkileyen teknolojik, küresel, sosyo-politik
      gelişmeleri ve uluslararası pazarlardaki fırsatları stratejinize ne ölçüde yansıttığınız
      sorgulandı. Bu çerçevede, kurumunuzun aynı soruları cevaplayan diğer kurumlar ile
      karşılaştırmalı durumu aşağıda gösterilmektedir:`,
      "benchmark-result": $props.detailReport.innovationStrategy_Crate_Benchmark.toString(),
      "score-result": $props.detailReport.innovationStrategy_Crate_Score.toString(),
      "table-title": 'Tablo 1.2',
      "detection-list": $props.detailReport.innovationStrategy_Crate_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "13",
      title: '1.3. Strateji Yayılımı',
      description: `Strateji yayılımı başlığı altında size yönelttiğimiz sorularda, kurumunuzda belirlenen
      inovasyon stratejisi kapsamındaki hedeflerin kurum içerisinde nasıl yayıldığını sorguladık.
      Bu kapsamda, kurumunuzda yer alan mavi, beyaz yaka çalışanlar ile üst ve orta
      kademe yöneticilere hedeflerin hangi ölçüde yansıtıldığı sorgulandı. Bu çerçevede,
      kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda
      gösterilmektedir:`,
      "benchmark-result": $props.detailReport.innovationStrategy_Spread_Benchmark.toString(),
      "score-result": $props.detailReport.innovationStrategy_Spread_Score.toString(),
      "table-title": 'Tablo 1.3',
      "detection-list": $props.detailReport.innovationStrategy_Spread_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"])
  ]))
}