import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ee7a595"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-md mx-auto my-2 block" }
const _hoisted_2 = { class: "w-full text-center block pt-4 break-after-avoid-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.title), 1),
    _createVNode(_component_apexchart, {
      type: "bar",
      height: "150",
      width: "100%",
      options: $setup.chart_options,
      series: [{ data: [$props.benchmark, $props.score] }]
    }, null, 8, ["options", "series"])
  ]))
}