
import { ApexOptions } from 'apexcharts';
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

import InnovationInfrastructure from './components/InnovationInfrastructure.vue';
import InnovationManagement from './components/InnovationManagement.vue';
import InnovationProcess from './components/InnovationProcess.vue';
import InnovationStrategy from './components/InnovationStrategy.vue';
import OpenInnovation from './components/OpenInnovation.vue';

import { useDate } from '@/hooks/useDate';
import { useFormStore } from '@/store/modules/form';

import TheBrandIcon from '@/components/TheBrandIcon.vue';
export default defineComponent({
  components: { InnovationStrategy, InnovationManagement, InnovationProcess, InnovationInfrastructure, OpenInnovation, TheBrandIcon },
  setup() {
    const store = useFormStore();
    const router = useRouter();
    const { format } = useDate();
    const session_id = store.session.id || router.currentRoute.value.query.sid?.toString();
    if (!session_id) {
      window.location.href = '/404';
      return false;
    }
    const report_loading = ref(true);
    const { detail_report, session } = storeToRefs(store);
    const chart_options: Ref<ApexOptions> = ref({});

    onMounted(async() => {
      await store.getSession(session_id);
      await store.detailReport();
      chart_options.value = {
        chart: {
          height: 550,
          type: 'radar',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
          distributed: false,
          style: {
            fontSize: '18px'
          },
          background: {
            enabled: true,
            borderWidth: 0,
            opacity: 0.4,
            dropShadow: {
              enabled: false
            }
          }
        },
        xaxis: {
          categories: [
            'İnovasyon Stratejisi',
            'İnovasyon Yönetimi',
            'İnovasyon Süreci',
            'İnovasyon Altyapısı',
            'Açık İnovasyon'
          ]
        },
        legend: {
          position: 'top'
        },
        series: [
          {
            name: 'Kurumsal',
            data: [
              detail_report.value.innovationStrategy_Score,
              detail_report.value.innovationManagment_Score,
              detail_report.value.innovationProcess_Score,
              detail_report.value.innovationInfrastructure_Score,
              detail_report.value.openInnovation_Score
            ]
          },
          {
            name: 'Benchmark',
            data: [
              detail_report.value.innovationStrategy_Benchmark,
              detail_report.value.innovationManagment_Benchmark,
              detail_report.value.innovationProcess_Benchmark,
              detail_report.value.innovationInfrastructure_Benchmark,
              detail_report.value.openInnovation_Benchmark
            ]
          }
        ],
        colors: [
          '#008ACF',
          '#082A75'
        ]
      };
      report_loading.value = false;
    });

    return {
      report_loading,
      session,
      detail_report,
      chart_options,
      // Methods
      format
    };
  }
});
