
import { PropType } from 'vue';

import ResultItem from './ResultItem.vue';

import { IDetailReport } from '@/services/types';
export default {
  components: { ResultItem },
  props: {
    detailReport: {
      type: Object as PropType<IDetailReport>,
      required: true
    }
  }
};
