import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_result_item = _resolveComponent("result-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_result_item, {
      id: "2",
      title: '2 - İnovasyon Yönetimi',
      "title-class": 'page-heading font-semibold',
      description: `Bir kurumda, inovasyon stratejisinin oluşturulması ve bu stratejiye bağlı olarak fikirlerin
         değere dönüştürülmesi süreci kısaca inovasyon yönetimi olarak adlandırılır. İnovasyon
         yönetiminin başarıya ulaşabilmesini sağlayan ana unsurlar, üst yönetimin desteği,
         çalışanların inovasyon yeteneği ve inovasyonun kurum kültürü haline gelebilmesidir.
         Kurumun yönetim yapısının yeni fikirlere açık olması, inovasyon faaliyetlerinin doğrudan
         bir üst düzey yöneticiye bağlı olarak yönetilmesi, inovasyon yönetiminden sorumlu bir
         birimin oluşturulması ve inovasyon faaliyetlerinin sistematik bir şekilde lansmanının ve
         iletişiminin yapılması, inovasyonun kurumsal yönetim içerisine entegre edilebildiğinin
         göstergesidir. İnovasyonu başarılı bir şekilde yönetmek isteyen bir kurumda, çalışanların
         inovasyon odaklı bilgi ve becerilerini artırmaya yönelik faaliyetlerin yapılması ve
         kurumdaki her düzey çalışanın belli ölçüde risk alacak şekilde özendirilerek kurum içi
         girişimciliğe teşvik edilmesi, çalışanların inovasyona olan ilgisini ve yeteneğini artırarak,
         zaman içerisinde inovasyonun kültür haline gelmesini sağlayacaktır.
         <p class='mt-4'>Vermiş olduğunuz yanıtlar neticesinde, İnovasyon Yetkinlik Envanterinizin İnovasyon Yönetimi boyutundaki skoru <b>${ _ctx.detailReport.innovationManagment_Score }</b> olarak hesaplanmıştır.<p>`,
      "benchmark-result": _ctx.detailReport.innovationManagment_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationManagment_Score.toString(),
      "table-title": 'Tablo 2'
    }, null, 8, ["description", "benchmark-result", "score-result"]),
    _createVNode(_component_result_item, {
      id: "21",
      title: '2.1. İnovasyon – Yönetim Yapısı Uyumsuzluğu',
      description: `Bu başlık kapsamında size yönelttiğimiz sorularda, kurumunuzun yeni fikirler karşısındaki
         tutumunu ve inovasyonu yönetim biçimini sorguladık. Yaptığımız analiz kapsamında,
         kurumunuzda inovasyon konusunda görevlendirilmiş bir yöneticinin, departmanın
         ve/veya komitenin varlığı konusunda soruları cevaplamanızı istedik. Aynı şekilde,
         kurumunuza bağlı bir Ar-Ge / Tasarım Merkezinin var olup olmadığını, inovasyon
         alanında toplantılar yapıp yapmadığınızı, inovasyon iletişimi stratejinizin olup olmadığını ve bu konuya yönelik verimliliğinizi
         ölçmeye yönelik göstergeler oluşturup oluşturmadığınızı değerlendirdik. Bu çerçevede,
         kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda
         gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationManagment_BadStructure_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationManagment_BadStructure_Score.toString(),
      "table-title": 'Tablo 2.1',
      "detection-list": _ctx.detailReport.innovationManagment_BadStructure_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "22",
      title: '2.2. İnovasyon Kültürü',
      description: `İnovasyon Kültürü başlığı altında size yönelttiğimiz sorularda, kurumunuzun zaman zaman
         inovasyon alanında yaşanması doğal olan başarısızlıklar karşısındaki tutumunu, şirket
         değerlerini ve inovasyon kavramını nasıl algıladığını sorguladık. Yaptığımız analiz
         kapsamında, kurum kültürünüzün, İK süreçlerinizin ve çalışma şartlarınızın kurumiçi
         girişimcilik yapmaya ne kadar uygun olduğunu değerlendirdik. Bu çerçevede,
         kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda
         gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationManagment_Culture_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationManagment_Culture_Score.toString(),
      "table-title": 'Tablo 2.2',
      "detection-list": _ctx.detailReport.innovationManagment_Culture_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"]),
    _createVNode(_component_result_item, {
      id: "23",
      title: '2.3. İnovasyon Yetenekleri',
      description: `Bu başlık kapsamında size yönelttiğimiz sorularda, kurumunuz inovasyon yapabilmek ve
         sonuçlarını ticarileştirebilmek için yeterli bilgi ve beceriye sahip olup olmadığını sorguladık.
         Yaptığımız analiz kapsamında, çalışanlara yönelik bu alanda verilen eğitimleri ve şirket
         dışından alınan girişim mentorluğu hizmetlerini değerlendirdik. Bu çerçevede,
         kurumunuzun aynı soruları cevaplayan diğer kurumlar ile karşılaştırmalı durumu aşağıda
         gösterilmektedir:`,
      "benchmark-result": _ctx.detailReport.innovationManagment_Capabilities_Benchmark.toString(),
      "score-result": _ctx.detailReport.innovationManagment_Capabilities_Score.toString(),
      "table-title": 'Tablo 2.3',
      "detection-list": _ctx.detailReport.innovationManagment_Capabilities_Sentences
    }, null, 8, ["title", "description", "benchmark-result", "score-result", "table-title", "detection-list"])
  ]))
}