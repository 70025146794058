import { format as datefns_format } from 'date-fns';

export function useDate() {
  function format(date: string, output_format: string): string {
    const new_date = new Date(date);
    return datefns_format(new_date, output_format);
  }
  return {
    format
  };
}
