import { ApexOptions } from 'apexcharts';

export default {
  props: {
    benchmark: {
      type: String,
      required: true
    },
    score: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const chart_options: ApexOptions = {
      chart: {
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: false
        },
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50px',
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: ['Benchmark', 'Kurumunuz'],
        crosshairs: {
          show: false
        },
        labels: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: false
        }
      },
      colors: [
        '#082A75',
        '#008ACF'
      ]
    };

    return {
      chart_options
    };
  }
};
